import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import Funding from 'components/Funding/Funding';

const FundingPage = ({ data }) => (
  <Layout page="funding">
    <Funding
      pageData={data.allContentfulPage.edges[0].node}
      servicesData={data.allContentfulService.edges}
      clientsData={data.allContentfulClients.edges}
      partnersData={data.allContentfulPartners.edges}
    />
  </Layout>
);

export default FundingPage;
export const query = graphql`
  query {
    allContentfulPage(
      filter: {
        slug: {
          eq: "funding"
        }
      }
    ) {
      edges {
        node {
          slug
          title
          subtitle { json }
          blockInfo { json }
          description { json }
          blockFeature { json }
          clients
          partners
          caseStudies
        }
      }
    }

    allContentfulService(
      filter: {
        typeOfService: { eq: "Funding" }
      }
    ) {
      pageInfo { hasNextPage }
      totalCount
      edges {
        node {
          id
          typeOfService
          title
          subtitle
          description { json }
        }
      }
    }
    allContentfulClients(filter:{typeOfService:{eq:"Funding"}}, limit:10)
    {
      edges{
        node{
          name
          logo { file { url } }
          clientUrl
          industry
        }
      }
    }
    allContentfulPartners(filter:{typeOfService:{eq:"Funding"}}, limit:10)
    {
      edges{
        node{
          name
          logo { file { url } }
          partnerUrl
          industry
        }
      }
    }

  }
`;

