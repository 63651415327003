import React, { Fragment } from 'react';

import getServiceColor from 'helpers/getServiceColor';

import Text from 'components/UI/Text/Text';
import Section from 'components/UI/Section/Section';
import Card from 'components/UI/Card/Card';
import Title from 'components/UI/Title/Title';
import Promo from 'components/UI/Promo/Promo';

import ContentfulRichText
  from 'components/UI/ContentfulRichText/ContentfulRichText';
import ServicesList from 'components/Services/List';

import TAILORED_SERVICES from 'data/tailored-services';

import { StyledImg } from './styled';

const SERVICE_COLOR = getServiceColor('Funding');
const Funding = ({ pageData, servicesData, clientsData, partnersData}) => {
  const {
    title,
    subtitle,
    description,
    blockInfo,
    blockFeature,
    clients,
    partners,
    caseStudies,
  } = pageData;

  return (
    <Section>
      <Title>
          <Text as="h2" line lineColor={SERVICE_COLOR} stack>{title}</Text>
          <Promo data={subtitle.json.content} />
          {description && (
            <ContentfulRichText
              richTextAST={description.json}
              headingsProps={{ as: 'h4' }}
              contentProps={{ variant: 'desc' }}
            />
          )}
        </Title>

        <Promo data={blockInfo.json.content} />

      <ServicesList
        title="Services"
        data={servicesData}
        serviceColor={SERVICE_COLOR}
      />

      {clientsData && partnersData && <Text as="h3" line>Community</Text>}
      {partnersData && (
        <Fragment>
          <Text as="h4" line lineColor={SERVICE_COLOR}>Partners</Text>
          <ul>
          {partnersData.map(({node: { logo, name, partnerUrl}}) => (
              <Card
                key={name}
                alt={name}
                small
                href={partnerUrl}
                icon={logo.file.url}
                bgColor="#fff"
              />
            ))}
          </ul>
        </Fragment>
      )}

      {clientsData && (
        <Fragment>
          <Text as="h4" line lineColor={SERVICE_COLOR}>Clients</Text>
          <ul>
          {clientsData.map(({node: { logo, name, clientUrl}}) => (
              <Card
                key={name}
                alt={name}
                small
                href={clientUrl}
                icon={logo.file.url}
                bgColor="#fff"
              />
            ))}
          </ul>
        </Fragment>
      )}
    </Section>
  );
}

export default Funding;
