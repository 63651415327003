import styled from 'styled-components';

export const StyledImg = styled.div`
  display: inline-block;
  width: 140px;
  height: 64px;
  margin-right: 10px;

  background: url(${({ src }) => src}) no-repeat center;
  background-size: 80%;
`;
